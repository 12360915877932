import React from 'react'
import FortuneWheelAnimator from './FortuneWheel/FortuneWheelAnimator'
import FortuneWheelProps from './FortuneWheelProps'
import {
  renderStandardBackground,
  renderStandardForeground,
} from './skins/skin_standard'
import TSConstants from '../../utils/TSConstants'
import { renderSkin } from './render_skin'
import MonthlyLotteryDraw from '@tootsweet/model/lottery/MonthlyLotteryDraw'

interface State {
  isPlaying: boolean
  TITLE_CONTAINER: HTMLDivElement | null
}

export default class FortuneWheelV2 extends React.PureComponent<
  FortuneWheelProps,
  State
> {
  FORTUNE_WHEEL?: FortuneWheelAnimator | null
  CONTAINER?: HTMLElement | null

  constructor(props: FortuneWheelProps) {
    super(props)
    this.play = this.play.bind(this)
    this.renderForeground = this.renderForeground.bind(this)
    this.renderBackground = this.renderBackground.bind(this)
    this.state = {
      isPlaying: false,
      TITLE_CONTAINER: null,
    }
  }

  render() {
    const width = this.props.width
    const height = this.props.height

    if (!width || !height) {
      return null
    }

    const titleHeight = this.state.TITLE_CONTAINER?.clientHeight || 0
    const wheelDimension = height - (titleHeight || 0)

    let wheelLeft = -Math.trunc(wheelDimension / 2.5)

    if (this.props.skin === TSConstants.WHEEL_SKIN_V2_NATURE) {
      wheelLeft = -Math.trunc(wheelDimension / 3)
    }

    const overflow =
      this.props.skin === TSConstants.WHEEL_SKIN_V2_NATURE
        ? 'hidden'
        : undefined

    return (
      <div
        ref={(ref) => (this.CONTAINER = ref)}
        style={{
          display: 'flex',
          flex: 1,
          width: '100%',
          height,
        }}
      >
        <div
          style={{
            position: 'absolute',
            width: '100%',
            height,
            zIndex: 1,
            overflow,
          }}
        >
          {this.renderBackground(width, height)}
        </div>

        <div
          style={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            position: 'absolute',
            width: '100%',
            height,
          }}
        >
          {!!this.props.renderTitle && (
            <div
              ref={(ref) => this.setState({ TITLE_CONTAINER: ref })}
              style={{ zIndex: 5 }}
              className="w-margin-top-2-thirds"
            >
              {this.props.renderTitle(this.props.isPlayStep, true)}
              <div className="wheel-title-separator" />
            </div>
          )}

          <div
            style={{
              left: wheelLeft,
              top: this.props.wheelTop,
              bottom: this.props.wheelBottom,
              position: 'absolute',
              zIndex: 2,
            }}
          >
            <FortuneWheelAnimator
              key={`${width}-${wheelDimension}`}
              ref={(ref) => (this.FORTUNE_WHEEL = ref)}
              wheelItems={this.props.wheelItems}
              colors={this.props.colors}
              width={wheelDimension}
              height={wheelDimension}
              onAnimationEnd={this.props.onFinishedSpinning}
              audioSource={`${this.props.config.PUBLIC_FILES_URL}/static/audio/click_wheel.mp3`}
              wheelDegInitial={this.props.wheelDegInitial}
              mlDraw={this.props.mlDraw}
            />
          </div>
        </div>

        <div
          style={{
            position: 'absolute',
            width: '100%',
            height,
            zIndex: 3,
            overflow,
          }}
        >
          {this.renderForeground(width, height, wheelDimension, wheelLeft)}
        </div>
      </div>
    )
  }

  play(index: number) {
    this.setState({
      isPlaying: true,
    })
    this.FORTUNE_WHEEL?.play(index)
  }

  private renderBackground(width: number, height: number) {
    if (this.props.skinObj) {
      return renderSkin(
        'bg',
        this.props.skinObj,
        this.props.colors,
        width,
        height
      )
    }
    if (this.props.renderBackground) {
      return this.props.renderBackground(width, height)
    }
    return renderStandardBackground(this.props.colors)
  }

  private renderForeground(
    width: number,
    height: number,
    wheelDimension?: number,
    wheelLeft?: number
  ) {
    if (this.props.skinObj) {
      return renderSkin(
        'fg',
        this.props.skinObj,
        this.props.colors,
        width,
        height
      )
    }
    if (this.props.renderForeground) {
      return this.props.renderForeground(
        width,
        height,
        wheelDimension || 0,
        wheelLeft || 0,
        this.props.wheelBottom || 0,
        this.state.isPlaying ? 1 : 0
      )
    }

    return renderStandardForeground(
      this.props.colors,
      false,
      this.state.isPlaying ? 1 : 0
    )
  }
}
