import React from 'react'
import { connect } from 'react-redux'
import { RootState } from '../../redux/combinedReducers'
import SlotMachineV1 from './v1/SlotMachineV1'
import SlotMachineV2 from './v2/SlotMachineV2'
import { renderSkin } from '../05_ScratchCard/render_skin'
import TitleV2 from '../../components/MonthlyLottery/Title/TitleV2'
import { tt } from '../../i18n'

interface SlotMachineProps {
  finalPlaceName: string
  isPlayStep: boolean
  onCTAClicked?: () => void
  onFinished?: () => void
}

type Props = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  SlotMachineProps

class SlotMachine extends React.PureComponent<Props> {
  constructor(props: Props) {
    super(props)
  }
  render() {
    const hasImage =
      !!this.props.lotteryConfig.smW &&
      !!this.props.lotteryConfig.smH &&
      !!this.props.lotteryConfig.smIH &&
      !!this.props.lotteryConfig.smUrl
    if (hasImage) {
      return (
        <>
          {renderSkin('bg', this.props.skin)}
          <div style={{ zIndex: 3 }}>
            <SlotMachineV1
              finalPlaceName={this.props.finalPlaceName}
              isPlayStep={this.props.isPlayStep}
              onCTAClicked={this.props.onCTAClicked}
              onFinished={this.props.onFinished}
            />
            {renderSkin('fg', this.props.skin)}
          </div>
        </>
      )
    }
    return (
      <>
        {renderSkin('bg', this.props.skin)}
        <TitleV2
          title1={
            this.props.skin?.slot_machine?.title || tt('slot_machine_title')
          }
          className="w-margin-top w-margin-bottom"
          title1ClassName="highlight"
        />
        <SlotMachineV2
          finalPlaceName={this.props.finalPlaceName}
          isPlayStep={this.props.isPlayStep}
          onCTAClicked={this.props.onCTAClicked}
          onFinished={this.props.onFinished}
        />
        {renderSkin('fg', this.props.skin)}
      </>
    )
  }
}

export const mapStateToProps = (state: RootState) => {
  return {
    lotteryConfig: state.lottery.lotteryConfig,
    skin: state.lottery.skin,
  }
}

export const mapDispatchToProps: any = {}

export default connect<
  ReturnType<typeof mapStateToProps>,
  typeof mapDispatchToProps,
  SlotMachineProps
>(
  //@ts-ignore
  mapStateToProps,
  mapDispatchToProps
)(SlotMachine)
