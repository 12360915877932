import * as React from 'react'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import LotteryV2StepType from '@tootsweet/model/lottery/LotteryV2StepType'
import WheelAnalyticsManager from '../../analytics/WheelAnalyticsManager'
import { tt } from '../../i18n'
import Scratch from '../../components/Scratch/Scratch'
import { setShowFooter } from '../../redux/actions/app'
import './styles.scss'
import { async_play } from '../../redux/actions/lottery'
import Modal from 'react-bootstrap/esm/Modal'
import WheelButton from '../../components/cta/WheelButton'
import { renderTitle } from '../helpers/renderTitle'
import { userSelector } from '../../redux/selectors/app'
import {
  isOptOutSelector,
  lotteryConfigSelector,
  lotteryGameConsumeSelector,
  prizeSelector,
  skinSelector,
} from '../../redux/selectors/lottery'
import { configSelector } from '../../redux/selectors/config'
import { renderSkin } from './render_skin'
import { renderLogo } from '../helpers/renderLogo'
import { aspect_ratio, AspectRatio } from '../../utils/aspect_ratio'

interface Props {
  finalPlaceName: string
  renderSnowflakes?: boolean
  force100PcHeight?: boolean
  onUserStartedScratching?: () => void
  onScratchComplete?: () => void
  gameStepType: LotteryV2StepType
  play?: boolean
  renderTitle?: (isPlayStep?: boolean) => any
}

const PADDING = aspect_ratio === AspectRatio._16_9 ? 5 : 15
const MSG =
  "Vous n'avez pas gratté la carte. Etes-vous sûr(e) de vouloir quitter le jeu ?"
const CALLBACK_1_PERCENT = 30
const N_CARDS = 4

const unloadCallback = (e: BeforeUnloadEvent) => {
  e.preventDefault()
  e.returnValue = MSG
  return MSG
}

const ScratchCard: React.FunctionComponent<Props> = (props) => {
  const dispatch = useDispatch()

  const user = useSelector(userSelector)
  const isOptOut = useSelector(isOptOutSelector)
  const siteConfig = useSelector(configSelector)
  const lotteryConfig = useSelector(lotteryConfigSelector)
  const prize = useSelector(prizeSelector)
  const game = useSelector(lotteryGameConsumeSelector)
  const skin = useSelector(skinSelector)

  const [idx, setIdx] = useState(0)

  useEffect(() => {
    const rand = Math.floor(Math.random() * 100)
    setIdx(Math.trunc(rand / (100 / N_CARDS)))
  }, [])

  const [container, setContainer] = useState<HTMLDivElement | null>(null)
  const [winningIndex, setWinningIndex] = useState(lotteryConfig?.sc1 ? 0 : idx)
  const [unblur, setUnblur] = useState<{ [key: number]: any }>({})
  const [showModal, setShowModal] = useState(false)
  const [hasPlayed, setHasPlayed] = useState(false)

  const play = () => {
    if (!user) {
      return
    }

    setHasPlayed(true)

    dispatch(
      async_play(
        siteConfig,
        user.firstName,
        user.paymentEmail,
        isOptOut,
        user.phone,
        true
      )
    )

    window.onbeforeunload = null
    window.removeEventListener('beforeunload', unloadCallback)
  }

  const getPrizeLabel = (isWinning: boolean, idx?: number) => {
    if (!isWinning) {
      return {
        label: tt('lost'),
        blur: idx !== undefined ? !unblur[idx] : true,
      }
    }

    let label: string
    let blur = false

    if (game && game.outcome) {
      label = prize?.label || ''
    } else if (game && !game.outcome) {
      label = tt('lost')
    } else {
      label = tt('mystery_gift')
      blur = true
    }

    return { label, blur }
  }

  const renderPrizeLabel = (isWinning: boolean, idx: number) => {
    if (!lotteryConfig) return

    const res = getPrizeLabel(isWinning, idx)

    let size = 20

    if (res.label.length > 100) {
      size = size / 2
    } else if (res.label.length > 65) {
      size = Math.trunc(size * (2 / 3))
    } else if (res.label.length > 40) {
      size = Math.trunc(size * (5 / 6))
    }

    return (
      <span
        className={`sc-prize-label ${res.blur ? 'sc-prize-blur' : ''}`}
        style={{
          fontSize: size,
          color: lotteryConfig.scFG || undefined,
        }}
      >
        {res.label}
      </span>
    )
  }

  const onUserStartedScratching = () => {
    if (props.play && !hasPlayed) {
      play()
    }

    if (props.onUserStartedScratching) {
      props.onUserStartedScratching()
    }
  }

  const renderBackground = () => {
    if (skin) {
      return renderSkin('bg', skin)
    }
  }

  const renderForeground = () => {
    if (skin) {
      return renderSkin('fg', skin)
    }
  }

  const renderGame = () => {
    if (!lotteryConfig) return

    const width2 = container?.clientWidth
    const height2 = container?.clientHeight

    let cardHeight: number
    let cardWidth: number

    if (lotteryConfig?.sc1) {
      cardHeight = Math.trunc(height2 ? height2 - PADDING * 2 : 0)
      cardWidth = Math.trunc(width2 ? width2 - PADDING * 2 : 0)
    } else {
      cardHeight = Math.trunc(height2 ? (height2 - PADDING * 3) / 2 : 0)
      cardWidth = Math.trunc(width2 ? (width2 - PADDING * 3) / 2 : 0)
    }

    // console.log("")
    // console.log("container?.clientWidth", container?.clientWidth)
    // console.log("container?.clientHeight", container?.clientHeight)
    // console.log("cardHeight", cardHeight)
    // console.log("cardWidth", cardWidth)

    return (
      <>
        {renderBackground()}
        <div
          className="w-padding-top-2-thirds text-center"
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {renderTitle(props.play, false, lotteryConfig, skin)}
        </div>
        <div
          ref={(ref) => setContainer(ref)}
          style={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 6,
          }}
        >
          {!lotteryConfig?.sc1 && render4Cards(cardWidth, cardHeight)}
          {lotteryConfig?.sc1 && render1Card(cardWidth, cardHeight)}
        </div>

        {renderForeground()}

        <Modal
          show={showModal}
          onHide={closeModal}
          centered={true}
          backdrop="static"
        >
          <Modal.Body>
            <div className="w-modal">
              <div className="container">
                <div className="row">
                  <div className="col-12 text-center">
                    {renderLogo(lotteryConfig)}
                  </div>
                </div>
                <div className="row w-margin-top">
                  <div className="col-12 text-center">
                    <p>
                      {lotteryConfig.scText ||
                        (lotteryConfig?.sc1
                          ? `${tt('scratch_to_discover_1_1_card')} ${tt(
                              'scratch_to_discover_2_1_card'
                            )}`
                          : `${tt('scratch_to_discover_1')} ${tt(
                              'scratch_to_discover_2'
                            )}`)}
                    </p>
                  </div>
                </div>
                <div className="row w-margin-top">
                  <div className="col-12">
                    <WheelButton text={tt('ok')} onClick={closeModal} />
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    )
  }

  const render1Card = (cardWidth: number, cardHeight: number) => {
    return (
      <div
        id="1c"
        style={{
          marginLeft: 30,
          marginRight: 30,
        }}
      >
        {!!cardWidth &&
          !!cardHeight &&
          renderScratch('a', cardWidth, cardHeight, 0, true)}
      </div>
    )
  }

  const render4Cards = (cardWidth: number, cardHeight: number) => {
    return (
      <>
        <div
          className="row"
          style={{
            marginLeft: 0,
            marginRight: 0,
            paddingTop: PADDING,
          }}
        >
          <div
            ref={(ref) => setContainer(ref)}
            className="col-6 d-flex justify-content-center pr-2"
            id="sc_container_1"
            style={{
              padding: 0,
            }}
          >
            {!!cardWidth &&
              !!cardHeight &&
              renderScratch('a', cardWidth, cardHeight, 0)}
          </div>
          <div
            className="col-6 d-flex justify-content-center pl-2"
            id="sc_container_2"
            style={{
              padding: 0,
            }}
          >
            {!!cardWidth &&
              !!cardHeight &&
              renderScratch('b', cardWidth, cardHeight, 1)}
          </div>
        </div>
        <div
          className="row"
          style={{
            marginLeft: 0,
            marginRight: 0,
            paddingTop: PADDING,
            paddingBottom: PADDING,
          }}
        >
          <div
            className="col-6 d-flex justify-content-center pr-2"
            id="sc_container_3"
            style={{
              padding: 0,
            }}
          >
            {!!cardWidth &&
              !!cardHeight &&
              renderScratch('c', cardWidth, cardHeight, 2)}
          </div>
          <div
            className="col-6 d-flex justify-content-center pl-2"
            id="sc_container_4"
            style={{
              padding: 0,
            }}
          >
            {!!cardWidth &&
              !!cardHeight &&
              renderScratch('d', cardWidth, cardHeight, 3)}
          </div>
        </div>
      </>
    )
  }

  const renderScratch = (
    key: string,
    cardWidth: number,
    cardHeight: number,
    pos: number,
    center?: boolean
  ) => {
    if (!lotteryConfig) return null

    const config = lotteryConfig

    if (!config || !config.scImg || !config.scImgH || !config.scImgW) {
      return null
    }

    const isWinning = props.play && pos === winningIndex

    let ratio = config.scImgW / config.scImgH
    let imgURL = `https://tootsweet.twic.pics/cdm/img/scratch/${config.scImg}?twic=v1`

    let height = Math.trunc(cardHeight)
    let width = Math.trunc(cardHeight * ratio)

    if (width > cardWidth) {
      width = Math.trunc(cardWidth)
      height = Math.trunc(cardWidth / ratio)
    }

    // console.log("")
    //
    // console.log("scImgW", config.scImgW)
    // console.log("scImgH", config.scImgH)
    // console.log("ratio", ratio)
    //
    // console.log("cardHeight", cardHeight)
    // console.log("cardWidth", cardWidth)
    //
    // console.log("height", height)
    // console.log("width", width)

    const imgWidth = width * window.devicePixelRatio
    const imgHeight = height * window.devicePixelRatio

    const finishPercent = props.play ? lotteryConfig?.scThreshold || 60 : 10000
    const callbackPercent = props.play
      ? 1
      : (lotteryConfig?.scThreshold ? lotteryConfig?.scThreshold / 2 : 45) || 45

    const style: any = {}

    if (center) {
      style.margin = '0 auto'
    }

    if (aspect_ratio === AspectRatio._16_9) {
      width = Math.trunc(0.8 * width)
      height = Math.trunc(0.8 * height)
    }

    return (
      <Scratch
        key={key}
        image={`${imgURL}/contain=${imgWidth}x${imgHeight}/quality=100`}
        width={width}
        height={height}
        finishPercent={finishPercent}
        onComplete={() => onScratchComplete(pos)}
        callback1={onUserStartedScratching}
        callback1Percent={callbackPercent || CALLBACK_1_PERCENT}
        fadeOutOnComplete={true}
        style={style}
      >
        <div
          style={{
            width,
            height,
            backgroundColor: lotteryConfig.scBG || '#ddd',
            maskMode: 'match-source',

            maskImage: `url(${imgURL}/contain=${width}x${height}/quality=100)`,
            maskRepeat: 'no-repeat',
            maskSize: `${width}px ${height}px`,

            WebkitMaskImage: `url(${imgURL}/contain=${width}x${height}/quality=100)`,
            WebkitMaskRepeat: 'no-repeat',
            WebkitMaskSize: `${width}px ${height}px`,
          }}
        />
        <div className="sc-prize-label-container">
          {renderPrizeLabel(isWinning ?? false, pos)}
        </div>
      </Scratch>
    )
  }

  const onScratchComplete = (idx: number) => {
    if (!props.onScratchComplete) {
      return
    }

    if (idx === winningIndex) {
      props.onScratchComplete()
    } else {
      setUnblur({
        ...unblur,
        [idx]: 1,
      })
    }
  }

  const closeModal = () => {
    WheelAnalyticsManager.logEvent('scratch_info_modal_closed')
    setShowModal(false)
  }

  useEffect(() => {
    if (!lotteryConfig) return

    if (props.play) {
      WheelAnalyticsManager.logEvent('scratch_card')
    }

    dispatch(setShowFooter(true))

    if (props.play) {
      window.addEventListener('beforeunload', unloadCallback)
      window.onbeforeunload = unloadCallback
    } else if (!!lotteryConfig.scText) {
      setShowModal(true)
    }

    return () => {
      if (props.play) {
        window.onbeforeunload = null
        window.removeEventListener('beforeunload', unloadCallback)
      }
    }
  }, [])

  useEffect(() => {
    if (!lotteryConfig) return

    if (lotteryConfig?.sc1) {
      setWinningIndex(0)
    }
  }, [lotteryConfig])

  return (
    <div
      style={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
      }}
    >
      {renderGame()}
      {!!lotteryConfig?.legalMentions && (
        <span className="cm-pad w-margin-top-half w-margin-bottom-half text-center">
          {lotteryConfig?.legalMentions}
        </span>
      )}
    </div>
  )
}

export default ScratchCard
