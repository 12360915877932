import React, { FunctionComponent, useEffect } from 'react'
import GenericGameStep from '../../components/GenericGameStep'
import LotteryV2StepType from '@tootsweet/model/lottery/LotteryV2StepType'
import { useSelector } from 'react-redux'
import DynamicFormModalContent from './CustomFormModalContent'
import WheelAnalyticsManager from '../../analytics/WheelAnalyticsManager'
import {
  loadingSelector,
  lotteryConfigSelector,
  prizeSelector,
  venueSelector,
  wheelLabelsSelector,
} from '../../redux/selectors/lottery'

interface Props {}

const DynamicForm: FunctionComponent<Props> = () => {
  useEffect(() => {
    WheelAnalyticsManager.logEvent('custom_form')
  }, [])

  return (
    <GenericGameStep
      stepType={LotteryV2StepType.CustomForm}
      renderModalBody={() => {
        return <DynamicFormModalContent />
      }}
    />
  )
}

export default DynamicForm
