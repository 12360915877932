import * as React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import WheelAnalyticsManager from '../../analytics/WheelAnalyticsManager'
import { TSUtils } from '../../utils/TSUtils'
import moment from 'moment'
import AdventCalendarItem from './components/AdventCalendarItem'
import ScratchCardChristmas from '../05_ScratchCard/ScratchCardChristmas'
import { tt } from '../../i18n'
import { RootState } from '../../redux/combinedReducers'
import {
  async_play,
  async_wheelFinishedSpinning,
  nextLotteryStep,
  setAdventCalendarShown,
} from '../../redux/actions/lottery'
import { setShowFooter } from '../../redux/actions/app'
import './styles.scss'
import LotteryV2StepType from '@tootsweet/model/lottery/LotteryV2StepType'
import {
  ZINDEX_BACKGROUND,
  ZINDEX_CANVAS,
  ZINDEX_SNOWFLAKES,
} from '../05_ScratchCard/constants'
import LotteryV2Step from '@tootsweet/model/lottery/LotteryV2Step'
import { aspect_ratio, AspectRatio } from '../../utils/aspect_ratio'
import { Garland, GarlandColor } from '../../components/christmas/Garland'
import SnowFlakes from '../../components/christmas/SnowFlakes'

//region assets
const GIFT_IMG = require('./img/gift.png')
const STAR_IMG = require('./img/star.png')

const SYMBOL_BLUE = require('./img/symbols/symbol_blue.png')
const SYMBOL_BLUE_WIDTH_TO_HEIGHT_RATIO = 632 / 264

const SYMBOL_GREEN = require('./img/symbols/symbol_green.png')
const SYMBOL_GREEN_WIDTH_TO_HEIGHT_RATIO = 614 / 260

const SYMBOL_RED = require('./img/symbols/symbol_red.png')
const SYMBOL_RED_WIDTH_TO_HEIGHT_RATIO = 632 / 264

const SYMBOL_ORANGE = require('./img/symbols/symbol_orange.png')
const SYMBOL_ORANGE_WIDTH_TO_HEIGHT_RATIO = 621 / 280

const CONTAINER_MARGIN_HORIZONTAL = 10

const MODE_ADVENT_CALENDAR = 0
const MODE_SCRATCH_CARD = 1

//endregion

interface State {
  container?: any
  height: number
  width: number
  topSkew: number
  isMobile: boolean
  loading: boolean
  showEmailModal: boolean
  mode: number
  skin: string
}

interface Props extends PropsFromRedux {
  finalPlaceName: string
  onUserStartedScratching?: () => void
  onScratchComplete?: () => void
  play: boolean
  hideTitle?: boolean
  inactive?: boolean
}

class AdventCalendar extends React.PureComponent<Props, State> {
  private CONTAINER: HTMLDivElement | null = null
  private audio: HTMLAudioElement | null = null

  private itemsMargin = 1
  private maxItemPerRow = 7
  private maxItemPerCol = aspect_ratio === AspectRatio._16_9 ? 11 : 11

  get itemWidth() {
    return Math.ceil(this.state.width / this.maxItemPerRow - this.itemsMargin)
  }

  get itemHeight() {
    return Math.ceil(this.state.height / this.maxItemPerCol)
  }

  get sideMargin() {
    return Math.ceil(
      (this.state.width -
        (this.itemsMargin + this.itemWidth) * this.maxItemPerRow) /
        2
    )
  }

  constructor(props: Props) {
    super(props)
    this.state = {
      width: 0,
      height: 0,
      isMobile: false,
      loading: true,
      showEmailModal: false,
      topSkew: 0,
      mode: this.props.adventCalendarShown
        ? MODE_SCRATCH_CARD
        : MODE_ADVENT_CALENDAR,
      skin: '-1',
    }
    this.startMusic = this.startMusic.bind(this)
    this.renderAdventCalendarRows = this.renderAdventCalendarRows.bind(this)
    this.renderDefaultAdventCalendar =
      this.renderDefaultAdventCalendar.bind(this)
    this.onBoxAnimationEnd = this.onBoxAnimationEnd.bind(this)
  }

  componentDidMount(): void {
    if (this.props.play) {
      WheelAnalyticsManager.logEvent('advent_calendar')
    }

    this.setState({
      isMobile: TSUtils.isMobile(),
    })
    this.startMusic()
    this.props.setShowFooter(true)
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
    if (!prevState.container !== this.state.container && this.state.container) {
      this.setState({
        width: this.state.container.clientWidth,
        height: this.state.container.clientHeight,
      })
    }

    if (
      this.props.lotteryConfig &&
      this.props.lotteryConfig.steps &&
      this.state.skin === '-1'
    ) {
      const gameStep = this.props.lotteryConfig.steps.find(
        (step: LotteryV2Step) =>
          step.stepType === LotteryV2StepType.AdventCalendar
      )
      if (gameStep) {
        this.setState({
          skin: gameStep.skin ?? '-1',
        })
      }
    }
  }

  componentWillUnmount() {
    if (this.audio) {
      this.audio.pause()
      this.audio = null
    }
  }

  render() {
    const height: string | number = this.state.container?.clientHeight || '100%'

    const play = this.props.play && this.props.adventCalendarShown
    const displayAdventCalendar =
      !this.props.adventCalendarShown &&
      this.state.mode === MODE_ADVENT_CALENDAR

    return (
      <div
        style={{ flex: 1, position: 'relative' }}
        ref={(ref) => this.setState({ container: ref })}
      >
        {AdventCalendar.renderBackground(height)}
        <div
          id="scratch-card"
          style={{
            position: 'absolute',
            zIndex: 1,
            width: '100%',
            opacity: this.props.adventCalendarShown ? 1 : 0,
            height,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <ScratchCardChristmas
            finalPlaceName={this.props.finalPlaceName}
            renderSnowflakes={false}
            onUserStartedScratching={this.props.onUserStartedScratching}
            onGameComplete={this.props.onScratchComplete}
            gameStepType={LotteryV2StepType.AdventCalendar}
            play={play}
          />
        </div>
        {displayAdventCalendar && this.renderAdventCalendar()}
        <SnowFlakes zIndex={ZINDEX_SNOWFLAKES} />
      </div>
    )
  }

  private static renderBackground(height: number | string) {
    return (
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          height: '100%',
          width: '100%',
        }}
      >
        <img
          className="twic sc-bg-img"
          data-src="image:/cdm/img/christmas/bg_advent.png"
          data-twic-transform="contain=WxH"
          style={{
            zIndex: ZINDEX_BACKGROUND,
            height: window.innerHeight,
            width: '100%',
          }}
        />
      </div>
    )
  }

  /**
   * @param i | index of the item starting from right to left
   * @param itemsCount | number of items on the current row
   */
  getLeft = (i: number, itemsCount: number) => {
    const j = i + 1
    return (
      CONTAINER_MARGIN_HORIZONTAL +
      this.state.width -
      (this.sideMargin + (j * this.itemWidth + j * this.itemsMargin)) -
      ((this.maxItemPerRow - itemsCount) * this.itemWidth) / 2 -
      (itemsCount % 2 === 0 ? 0 : this.itemsMargin)
    )
  }

  /**
   * @param i | the index of the column from bottom to top
   */
  getTop = (i: number) => {
    return (
      this.state.topSkew +
      (this.state.topSkew || 0) +
      (this.state.height - this.itemHeight * 3) -
      this.itemHeight * i
    )
  }

  shouldAnimateOnBoxClick = (day: number) => {
    let dayOfMonth = moment().date()
    return !this.props.inactive && day === dayOfMonth
  }

  onBoxAnimationEnd = () => {
    const animOpacity = document
      .getElementById('ts-advent-calendar')
      ?.animate([{ opacity: 1 }, { opacity: 0 }], {
        fill: 'both',
        duration: 1500,
        easing: 'ease-out',
      })

    document
      .getElementById('scratch-card')
      ?.animate([{ opacity: 0 }, { opacity: 1 }], {
        fill: 'both',
        duration: 1500,
        easing: 'ease-out',
      })

    if (animOpacity) {
      animOpacity.onfinish = () => {
        this.setState({ mode: MODE_SCRATCH_CARD })
        this.props.setAdventCalendarShown(true)
      }
    }
  }

  private renderAdventCalendar() {
    return (
      <div
        id="ts-advent-calendar"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          width: '100%',
          height: this.state.height,
          zIndex: 2,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {this.renderDefaultAdventCalendar()}
      </div>
    )
  }

  private renderDefaultAdventCalendar() {
    return (
      <>
        <div className="sc-fg-container" style={{ pointerEvents: 'none' }}>
          <h1
            className="h1-advent-calendar text-center"
            style={{
              visibility: !!this.props.hideTitle ? 'hidden' : undefined,
            }}
          >
            {tt('advent_calendar_open_box')}
          </h1>
        </div>

        <div
          id="abc"
          ref={(ref) => (this.CONTAINER = ref)}
          style={{
            display: 'flex',
            flex: 1,

            // zoom: 0.9,
            transform: 'translateX(-10px)',
          }}
        >
          <div
            id="def"
            style={{
              width: '100%',
              height: '100%',
              zIndex: ZINDEX_CANVAS,
              justifySelf: 'center',
            }}
          >
            <img
              style={{
                position: 'absolute',
                left: this.getLeft(0, 1),
                top: this.getTop(7.1),
              }}
              width={this.itemWidth}
              height={this.itemHeight}
              src={STAR_IMG}
            />
            {this.renderAdventCalendarRows()}
            {this.renderGarlands()}
          </div>

          <img
            style={{
              position: 'absolute',
              left: -this.state.width * 0.1,
              top: this.getTop(0) + this.itemHeight,
              pointerEvents: 'none',
            }}
            width={(this.state.width || 1) * 1.4}
            src={GIFT_IMG}
          />
        </div>
      </>
    )
  }

  private renderGarlands() {
    let garlandSkewCoefs = {
      small: 0.6,
      small2: 0.7,
      two: 0.9,
      three: 0.8,
    }

    switch (aspect_ratio) {
      case AspectRatio._16_9:
        garlandSkewCoefs = {
          small: 0.6,
          small2: 0.7,
          two: 0.8,
          three: 0.7,
        }
    }

    return (
      <>
        {/* Row 2 */}
        <Garland
          color={GarlandColor.Yellow}
          top={this.getTop(5) + this.itemHeight * garlandSkewCoefs.small2}
          left={this.getLeft(1, 2) - this.itemWidth / 2}
        />
        <Garland
          color={GarlandColor.Red}
          top={this.getTop(5) + this.itemHeight * garlandSkewCoefs.two}
          left={this.getLeft(1, 2)}
        />
        <Garland
          color={GarlandColor.Blue}
          top={this.getTop(5) + this.itemHeight * garlandSkewCoefs.three}
          left={this.getLeft(0, 2)}
        />
        <Garland
          color={GarlandColor.Yellow}
          top={this.getTop(5) + this.itemHeight * garlandSkewCoefs.two}
          left={this.getLeft(0, 2) + this.itemWidth / 2}
        />
        <Garland
          color={GarlandColor.Red}
          top={this.getTop(5) + this.itemHeight * garlandSkewCoefs.small}
          left={this.getLeft(0, 2) + this.itemWidth * 1.2}
        />

        {/* Row 3 */}
        <Garland
          color={GarlandColor.Red}
          top={this.getTop(3) + this.itemHeight * garlandSkewCoefs.small}
          left={this.getLeft(3, 4) * garlandSkewCoefs.small2}
        />
        <Garland
          color={GarlandColor.Blue}
          top={this.getTop(3) + this.itemHeight * garlandSkewCoefs.two}
          left={this.getLeft(3, 4)}
        />
        <Garland
          color={GarlandColor.Yellow}
          top={this.getTop(3) + this.itemHeight * garlandSkewCoefs.three}
          left={this.getLeft(3, 4) + this.itemWidth / 2}
        />
        <Garland
          color={GarlandColor.Red}
          top={this.getTop(3) + this.itemHeight * garlandSkewCoefs.two}
          left={this.getLeft(2, 4) + this.itemWidth / 2}
        />
        <Garland
          color={GarlandColor.Blue}
          top={this.getTop(3) + this.itemHeight * garlandSkewCoefs.three}
          left={this.getLeft(1, 4)}
        />
        <Garland
          color={GarlandColor.Yellow}
          top={this.getTop(3) + this.itemHeight * garlandSkewCoefs.two}
          left={this.getLeft(1, 4) + this.itemWidth}
        />
        <Garland
          color={GarlandColor.Red}
          top={this.getTop(3) + this.itemHeight * garlandSkewCoefs.two}
          left={this.getLeft(0, 4) + this.itemWidth / 2}
        />
        <Garland
          color={GarlandColor.Blue}
          top={this.getTop(3) + this.itemHeight * garlandSkewCoefs.small}
          left={this.getLeft(0, 4) + this.itemWidth * 1.2}
        />

        {/* Row 5 */}
        <Garland
          color={GarlandColor.Blue}
          top={this.getTop(1) + this.itemHeight}
          left={this.getLeft(1, 3) * garlandSkewCoefs.small}
        />
        <Garland
          color={GarlandColor.Yellow}
          top={this.getTop(1) + this.itemHeight * garlandSkewCoefs.two}
          left={this.getLeft(2, 3) + this.itemWidth / 2}
        />
        <Garland
          color={GarlandColor.Red}
          top={this.getTop(1) + this.itemHeight * garlandSkewCoefs.three}
          left={this.getLeft(1, 3) + this.itemWidth / 3}
        />
        <Garland
          color={GarlandColor.Blue}
          top={this.getTop(1) + this.itemHeight * garlandSkewCoefs.two}
          left={this.getLeft(0, 3) + this.itemWidth / 3}
        />
        <Garland
          color={GarlandColor.Yellow}
          top={this.getTop(1) + this.itemHeight}
          left={this.getLeft(0, 3) + this.itemWidth}
        />
      </>
    )
  }

  private async startMusic() {
    this.audio = new Audio(
      'https://s3.eu-west-3.amazonaws.com/cadeaudelamaison.com/kadow/sound/jinglebells.ogg'
    )

    const canPlayOgg =
      !!this.audio.canPlayType &&
      this.audio.canPlayType('audio/ogg; codecs="vorbis"') != ''

    if (!canPlayOgg) {
      this.audio = null
      return
    }

    this.audio.loop = true
    try {
      await this.audio.play()
    } catch (err) {
      console.error(err)
    }
  }

  private renderAdventCalendarRows() {
    return (
      <>
        {this.renderSingleRow({
          items: [
            { day: 10, color: 'green' },
            { day: 21, color: 'green' },
            { day: 5, color: 'yellow' },
          ],
          col: 0,
        })}

        {this.renderSingleRow({
          items: [
            {
              day: 7,
              color: 'yellow',
              symbol: SYMBOL_ORANGE,
              symbolRatio: SYMBOL_ORANGE_WIDTH_TO_HEIGHT_RATIO,
            },
            { day: 18, color: 'red' },
            { day: 13, color: 'blue' },
            { day: 9, color: 'red' },
            { day: 3, color: 'red' },
            { day: 11, color: 'green' },
          ],
          col: 1,
        })}

        {this.renderSingleRow({
          items: [
            {
              day: 15,
              color: 'blue',
              symbol: SYMBOL_BLUE,
              symbolRatio: SYMBOL_BLUE_WIDTH_TO_HEIGHT_RATIO,
            },
            { day: 6, color: 'green' },
            { day: 19, color: 'yellow' },
            { day: 8, color: 'red' },
            { day: 16, color: 'green' },
          ],
          col: 2,
        })}

        {this.renderSingleRow({
          items: [
            {
              day: 20,
              color: 'green',
              symbol: SYMBOL_GREEN,
              symbolRatio: SYMBOL_GREEN_WIDTH_TO_HEIGHT_RATIO,
            },
            { day: 2, color: 'red' },
            { day: 14, color: 'blue' },
            { day: 23, color: 'yellow' },
          ],
          col: 3,
        })}

        {this.renderSingleRow({
          items: [
            {
              day: 17,
              color: 'red',
              symbol: SYMBOL_RED,
              symbolRatio: SYMBOL_RED_WIDTH_TO_HEIGHT_RATIO,
            },
            { day: 1, color: 'yellow' },
            { day: 22, color: 'blue' },
          ],
          col: 4,
        })}

        {this.renderSingleRow({
          items: [
            {
              day: 4,
              color: 'yellow',
              leftSnow: true,
              frontSnow: 'front_snow_left',
              symbol: SYMBOL_ORANGE,
              symbolRatio: SYMBOL_ORANGE_WIDTH_TO_HEIGHT_RATIO,
            },
            { day: 12, color: 'green', frontSnow: 'front_snow_right' },
          ],
          col: 5,
        })}

        {this.renderSingleRow({
          items: [
            {
              day: 24,
              color: 'red',
              frontSnow: 'front_snow',
              symbol: SYMBOL_RED,
              symbolRatio: SYMBOL_RED_WIDTH_TO_HEIGHT_RATIO,
            },
          ],
          col: 6,
        })}
      </>
    )
  }

  private renderSingleRow = (args: {
    items: {
      day: number
      color: 'green' | 'blue' | 'red' | 'yellow'
      frontSnow?: 'front_snow' | 'front_snow_left' | 'front_snow_right'
      leftSnow?: boolean
      symbol?: any
      symbolRatio?: number
    }[]
    col: number
  }) => {
    const { items, col } = args
    const now = moment()
    return items.reverse().map((item, i) => {
      return (
        <AdventCalendarItem
          key={i}
          color={item.color}
          top={this.getTop(col)}
          left={this.getLeft(i, items.length)}
          width={this.itemWidth}
          height={this.itemHeight}
          day={item.day}
          leftSnow={item.leftSnow}
          frontSnow={item.frontSnow}
          shouldAnimate={this.shouldAnimateOnBoxClick}
          onAnimationEnd={this.onBoxAnimationEnd}
          blink={now.date() === item.day}
          symbol={item.symbol}
          symbolRatio={item.symbolRatio}
        />
      )
    })
  }
}

export const mapStateToProps = (state: RootState) => {
  return {
    user: state.app.user,
    loading: state.lottery.loading,
    status: state.lottery.status,
    siteConfig: state.config,
    step: state.lottery.step,
    wheelLabels: state.lottery.wheelLabels,
    prize: state.lottery.prize,
    lotteryConfig: state.lottery.lotteryConfig,
    adventCalendarShown: state.lottery.adventCalendarShown,
  }
}

export const mapDispatchToProps = {
  nextLotteryStep,
  setShowFooter,
  async_play,
  async_wheelFinishedSpinning,
  setAdventCalendarShown,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(AdventCalendar)
