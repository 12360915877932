import React, { useCallback, useEffect, useRef, useState } from 'react'
import Twemoji from 'react-twemoji'
import { gsap, Power0 } from 'gsap'
import styles from './Fakir.module.scss'
import clsx from 'clsx'
import { getPath, rowsPattern, shuffle, testBoundsOfPath } from './utils'
import { useDispatch, useSelector } from 'react-redux'
import {
  isOptOutSelector,
  lotteryConfigSelector,
  prizeSelector,
  skinSelector,
} from '../../redux/selectors/lottery'
import { async_play } from '../../redux/actions/lottery'
import { configSelector } from '../../redux/selectors/config'
import { userSelector } from '../../redux/selectors/app'
import { WheelItem } from '@tootsweet/model/lottery/LotteryConfigurationResponse'
import { renderSkin } from './render_skin'
import FakirBall from './FakirBall'
import { aspect_ratio, AspectRatio } from '../../utils/aspect_ratio'
import TitleV2 from '../../components/MonthlyLottery/Title/TitleV2'
import { tt } from '../../i18n'
import WheelButton from '../../components/cta/WheelButton'
import TSConstants from '../../utils/TSConstants'

interface Props {
  isPlayStep?: boolean
  onFinished?: () => void
  onGameStarted?: () => void
}

const SWING_START = 20
const SWING_STOP = 80
const SLOTS_MARGIN_TOP = 15
const SLOTS_MARGIN_TOP_16_9 = 10

const GRID_SIZE_16_9 = 25
const GRID_SIZE = 28

const BALL_TOP = 15
const MESH_MARGIN_VERTICAL = 10

let rowGap = 15

switch (aspect_ratio) {
  case AspectRatio._16_9:
    rowGap = 15
    break
}

const Fakir = ({ isPlayStep, onFinished }: Props) => {
  const ballRef = useRef<HTMLDivElement>(null)
  const holeRef = useRef<HTMLDivElement>(null)
  const giftRef = useRef<HTMLDivElement | null>(null)
  const pinsRef = useRef<Array<HTMLDivElement>>({} as Array<HTMLDivElement>)
  const timeline = useRef<gsap.core.Timeline>()
  const animationDirection = useRef<number>(1)
  const isRunStarted = useRef<boolean>(false)

  const dispatch = useDispatch()

  const lotteryConfig = useSelector(lotteryConfigSelector)
  const prize = useSelector(prizeSelector)
  const siteConfig = useSelector(configSelector)
  const user = useSelector(userSelector)
  const isOptOut = useSelector(isOptOutSelector)
  const skin = useSelector(skinSelector)

  const [showRange, setShowRange] = useState<boolean>(true)
  const [winningIndex, setWinningIndex] = useState<number>(-1)
  const [gifts, setGifts] = useState<Array<WheelItem>>([])
  const [runStarted, setRunStarted] = useState(false)

  const renderBackground = () => {
    if (skin) {
      return renderSkin('bg', skin)
    }
  }

  const renderForeground = () => {
    if (skin) {
      return renderSkin('fg', skin)
    }
  }

  const renderHoles = () => (
    <>
      <div
        ref={holeRef}
        className={clsx(styles.hole, styles[`hole--1`])}
        style={holeStyle}
      ></div>
      <div
        className={clsx(styles.hole, styles[`hole--2`])}
        style={holeStyle}
      ></div>
      <div
        className={clsx(styles.hole, styles[`hole--3`])}
        style={holeStyle}
      ></div>
      <div
        className={clsx(styles.hole, styles[`hole--4`])}
        style={holeStyle}
      ></div>
      <div
        className={clsx(styles.hole, styles[`hole--5`])}
        style={holeStyle}
      ></div>
    </>
  )

  const renderGifts = () => (
    <>
      {gifts?.map((gift, index) => {
        const id = gift.isGC ? 'ml-token-icon' : gift.isLost ? 'lost' : 'won'
        return (
          <div
            className={clsx(styles.gift, styles[`gift--${index + 1}`])}
            id={id}
            key={index}
            ref={(ref) => {
              if (index !== winningIndex) {
                return
              }

              giftRef.current = ref
            }}
          >
            {gift.isGC && (
              <img
                className="twic"
                data-src={`image:/cdm/kadow/glm/${
                  lotteryConfig?.mlToken || 'token.png'
                }`}
                data-src-transform="contain=WxH"
              />
            )}
            {!gift.isGC && !gift.isLost && gift.e}
            {!gift.isGC && gift.isLost && '😢'}
          </div>
        )
      })}
    </>
  )

  const renderBallAndArrow = () => (
    <>
      <FakirBall ref={ballRef} skin={skin} margin={BALL_TOP} />
      {showRange && (
        <>
          <div
            className={styles.range}
            style={{
              top: `calc(0% - ${BALL_TOP}px)`,
            }}
          >
            <div className={styles.line}></div>
          </div>
        </>
      )}
    </>
  )

  const renderDots = () => (
    <>
      {[...Array(44)].map((item, index) => (
        <div
          className={clsx(styles.cell, styles[`cell--${index + 1}`])}
          key={index}
          ref={(ref) => {
            if (!ref) {
              return
            }
            pinsRef.current[index] = ref
          }}
        >
          <div className={styles.pin}></div>
        </div>
      ))}
    </>
  )

  const startRun = useCallback(() => {
    if (
      isRunStarted.current ||
      !timeline.current ||
      !ballRef.current ||
      !pinsRef.current
    )
      return

    setRunStarted(true)

    setShowRange(false)
    isRunStarted.current = true

    // Stop swing animation
    timeline.current.clear()

    // Get animation direction (reverse or not)
    const direction = animationDirection.current ?? 1

    // Get start index for falling animation
    const currentBallPosition = parseFloat(ballRef.current?.style.left ?? '')
    const swingStep = 100 / 11
    const fallStep = 100 / 8
    const innertion =
      direction > 0
        ? currentBallPosition % swingStep > swingStep / 2 &&
          SWING_STOP - currentBallPosition > swingStep
        : currentBallPosition % swingStep < swingStep / 2 &&
          currentBallPosition - SWING_START < swingStep

    const startIndex =
      Math.floor(currentBallPosition / swingStep) +
      direction * Number(innertion)

    let rowIndex = 0
    let colIndex = rowsPattern[0].includes(startIndex)
      ? startIndex
      : startIndex + direction
    let pinIndex = 0

    let currentTop = `calc(0% - ${BALL_TOP}px)`
    let currentLeft = getComputedStyle(ballRef.current).left

    // move ball
    const duration = 1
    const ease = 'power2.out'

    const pinHeight = pinsRef.current[0].children[0].getClientRects()[0].height

    const drop = (right: boolean) => {
      const tweenVars = {
        duration: duration / 1.5,
        ease: 'power2.in',
        top: `calc(${fallStep * (rowIndex + 1)}% - ${pinHeight / 2}px)`, // move to the first pin
        left: `${swingStep * (right ? colIndex++ : colIndex--)}%`,
        transform: `translate(0px, -50%)`,
      } as gsap.TweenVars

      if (ballRef.current) {
        currentTop = `calc(${fallStep * (rowIndex + 1)}% - ${pinHeight / 2}px)`
        currentLeft = `${swingStep * (right ? colIndex - 1 : colIndex + 1)}%`
      }

      return tweenVars
    }

    const fall = (right: boolean) => {
      const tweenVars = {
        duration,
        ease,
        keyframes: {
          top: [
            currentTop,
            `calc(${fallStep * (rowIndex + 1)}% - ${pinHeight * 3.5}px)`, // bounce up from pin
            `calc(${fallStep * (rowIndex + 1)}% - ${pinHeight / 2}px)`, // move to the next pin
          ],
          left: [
            currentLeft,
            `${swingStep * (right ? colIndex++ : colIndex--)}%`,
          ],
        },
        transform: `translate(0px, -50%)`,
      } as gsap.TweenVars

      if (ballRef.current) {
        currentTop = `calc(${fallStep * (rowIndex + 1)}% - ${pinHeight / 2}px)`
        currentLeft = `${swingStep * (right ? colIndex - 1 : colIndex + 1)}%`
      }

      return tweenVars
    }

    const land = (right: boolean) => {
      const tweenVars = {
        duration,
        ease,
        keyframes: {
          top: [
            currentTop,
            `calc(${fallStep * (rowIndex + 1)}% - ${pinHeight * 3.5}px)`, // bounce up from pin
            'calc(100% + ' + SLOTS_MARGIN_TOP + 'px)',
          ],
          left: [
            currentLeft,
            `${swingStep * (right ? colIndex++ : colIndex--)}%`,
          ],
        },
        transform: `translate(0px, -50%)`,
      } as gsap.TweenVars

      if (ballRef.current) {
        currentTop = `calc(${fallStep * (rowIndex + 1)}% - ${pinHeight / 2}px)`
        currentLeft = `${swingStep * (right ? colIndex - 1 : colIndex + 1)}%`
      }

      return tweenVars
    }

    const shining = () =>
      ({
        keyframes: {
          boxShadow: [
            '0px 0 0px 0px #f4ff00',
            '0px 0 15px 10px #f4ff00',
            '0px 0 0px 0px #f4ff00',
          ],
        },
        duration: 0.5,
      } as gsap.TweenVars)

    const moveLeft = (timeLabel = '<') => {
      const pin = pinsRef.current[pinIndex + Math.floor(colIndex / 2)]

      timeline.current &&
        timeline.current
          .to(ballRef.current, fall(false), timeLabel)
          .addLabel(`endOffFall${rowIndex}`, '<')
          .to(pin.firstChild, shining(), '<50%')
    }

    const moveRight = (timeLabel = '<') => {
      const pin = pinsRef.current[pinIndex + Math.floor(colIndex / 2)]

      timeline.current &&
        timeline.current
          .to(ballRef.current, fall(true), timeLabel)
          .addLabel(`endOffFall${rowIndex}`, '<')
          .to(pin.firstChild, shining(), '<50%')
    }

    const move = (right: boolean, timeLabel = `endOffFall${rowIndex}`) =>
      right ? moveRight(timeLabel) : moveLeft(timeLabel)

    // Randomize path
    let path = []
    do {
      path = shuffle(getPath(colIndex, winningIndex))
      // test if do not goes out of space of the playfield
    } while (!testBoundsOfPath(path, colIndex, 0, 9))

    const rowIndexMax = 8

    while (rowIndex < rowIndexMax) {
      pinIndex += (rowIndex + 1) % 2 === 0 ? 6 : 5

      switch (rowIndex) {
        case 0: {
          const pin = pinsRef.current[pinIndex + Math.floor(colIndex / 2)]
          timeline.current
            .to(ballRef.current, drop(path[rowIndex]), '<')
            .to(pin.firstChild, shining(), '>')

          break
        }
        case 1: {
          move(path[rowIndex], '<')
          break
        }
        case 7: {
          timeline.current.to(ballRef.current, land(path[rowIndex]), '<')
          break
        }
        default: {
          move(path[rowIndex], '<')
          break
        }
      }

      rowIndex++
    }

    timeline.current.to(
      giftRef.current,
      {
        translateY: '-30%',
        yoyo: true,
        duration: 0.25,
        repeat: 1,
      },
      '<60%'
    )
    timeline.current.then(onFinished)
  }, [winningIndex])

  useEffect(() => {
    if (timeline.current !== undefined) {
      return
    }

    timeline.current = gsap.timeline()
    timeline.current.fromTo(
      ballRef.current,
      {
        left: `${SWING_START}%`,
      },
      {
        left: `${SWING_STOP}%`,
        yoyo: true,
        repeat: -1,
        ease: Power0.easeNone,
        duration: 1,
        onRepeat: () => {
          animationDirection.current = animationDirection.current * -1
        },
      }
    )
  }, [])

  useEffect(() => {
    if (isPlayStep) {
      dispatch(
        async_play(
          siteConfig,
          user?.firstName,
          user?.paymentEmail,
          isOptOut,
          user?.phone,
          true
        )
      )
    }
  }, [])

  useEffect(() => {
    if (!lotteryConfig?.wheelItems) return
    setGifts(lotteryConfig?.wheelItems)
  }, [lotteryConfig?.wheelItems])

  useEffect(() => {
    if (!prize) return
    if (prize.win) {
      const winningIndex = gifts.findIndex((gift) => gift.id === prize.giftId)
      setWinningIndex(winningIndex)
    } else {
      const winningIndex = gifts.findIndex((gift) => gift.isLost)
      setWinningIndex(winningIndex)
    }
  }, [prize, gifts])

  if (!lotteryConfig) return <></>

  const holeStyle: any = {
    borderBottomLeftRadius: `${holeRef.current?.clientHeight}px`,
    borderBottomRightRadius: `${holeRef.current?.clientHeight}px`,
  }
  if (skin?.fakir?.holeImg) {
    holeStyle.backgroundImage = `url('${skin?.fakir?.holeImg}')`
    holeStyle.backgroundRepeat = 'no-repeat'
    holeStyle.backgroundSize = 'contain'
    holeStyle.aspectRatio = '1/1'
  } else {
    holeStyle.backgroundColor = lotteryConfig?.colFooterBack || '#29235c'
  }

  const rect = holeRef?.current?.getBoundingClientRect()
  const bottomImgTop = Math.trunc((rect?.top || 0) + (rect?.height || 0) / 2)
  const bottomImgHeight = Math.trunc(window.innerHeight - bottomImgTop)

  return (
    <>
      <style>
        {`
          :root {
            --fakir-line-color: ${skin?.fakir?.lineColor || 'white'};
            --fakir-pin: ${skin?.fakir?.pinColor || '#f1ce4c'};
          }
        `}
      </style>
      {!!skin?.fakir?.bottomImg && (
        <img
          src={TSConstants.PLACEHOLDER_URL}
          id="fakir-bottom-img"
          className="twic"
          data-src={`image:${skin.fakir.bottomImg}`}
          data-src-transform="contain=WxH"
          style={{
            position: 'absolute',
            top: bottomImgTop,
            height: bottomImgHeight,
            width: '100%',
          }}
        />
      )}
      <Twemoji
        options={{
          base: 'https://d19y19rxbpc628.cloudfront.net/jetpack/twemoji/14.0.1/',
          folder: 'svg',
          ext: '.svg',
        }}
        // @ts-ignore
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
        }}
      >
        <TitleV2
          className={
            aspect_ratio === AspectRatio._16_9
              ? 'w-margin-top-2-thirds w-margin-top-2-thirds'
              : 'w-margin-top w-margin-bottom'
          }
          title1={tt('drop')}
          title2={tt('the_ball')}
        />
        {renderBackground()}
        <div
          id={'fakir-container'}
          style={{
            margin: '0 auto',
            height: '100%',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            zIndex: 4,
          }}
        >
          <div
            className={styles.container}
            style={{
              marginTop: MESH_MARGIN_VERTICAL,
            }}
          >
            <div
              className={[
                styles.mesh,
                aspect_ratio === AspectRatio._16_9
                  ? styles.mesh_grid_16_9
                  : styles.mesh_grid_normal,
              ].join(' ')}
              style={{
                gridTemplate:
                  aspect_ratio === AspectRatio._16_9
                    ? `repeat(8, 1fr) / repeat(11, minmax(9%, ${GRID_SIZE_16_9}px))`
                    : `repeat(8, 1fr) / repeat(11, minmax(9%, ${GRID_SIZE}px))`,
              }}
            >
              {renderBallAndArrow()}
              {renderDots()}
            </div>
            <div
              className={styles.slots}
              style={{
                marginTop:
                  aspect_ratio === AspectRatio._16_9
                    ? SLOTS_MARGIN_TOP_16_9
                    : SLOTS_MARGIN_TOP,
              }}
            >
              <div className={styles.wrapper}>
                {renderHoles()}
                {renderGifts()}
              </div>
            </div>
          </div>
          {renderForeground()}
        </div>
      </Twemoji>
      {!runStarted && isPlayStep && (
        <WheelButton
          text={tt('drop_it_now')}
          isFixedBottom={true}
          onClick={isPlayStep ? startRun : undefined}
        />
      )}
    </>
  )
}

export default Fakir
