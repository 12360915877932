import React, { FunctionComponent } from 'react'
import { tt } from '../i18n'
import { useSelector } from 'react-redux'
import { venueSelector } from '../redux/selectors/lottery'

interface Props {
  customTitle?: string
  customText?: string
}

const Disabled: FunctionComponent<Props> = ({ customTitle, customText }) => {
  const venue = useSelector(venueSelector)
  return (
    <>
      <h2 className="h2 highlight w-margin-bottom cm-pad w-margin-top-2-thirds">
        {customTitle || tt('suspended')}
      </h2>
      <p className="w-margin-bottom cm-pad">
        {customText || tt('suspended_desc')}
      </p>
      <p className="w-margin-bottom cm-pad">{venue?.name}</p>
    </>
  )
}

export default Disabled
