import * as React from 'react'
import FortuneWheelProps from './FortuneWheelProps'
import TSConstants from '../../utils/TSConstants'
import FortuneWheelV1 from './FortuneWheelV1'
import FortuneWheelV2 from './FortuneWheelV2'
import { TSUtils } from '../../utils/TSUtils'
import { connect, ConnectedProps } from 'react-redux'
import { RootState } from '../../redux/combinedReducers'
import WheelButton from '../cta/WheelButton'
import { tt } from '../../i18n'
import WheelAnalyticsManager from '../../analytics/WheelAnalyticsManager'
import { async_play } from '../../redux/actions/lottery'
import WheelColorsI, {
  COLOR_WHITE,
  DEFAULT_COLOR_BLUE,
  DEFAULT_COLOR_DARK_BLUE,
  DEFAULT_COLOR_DARK_YELLOW,
  DEFAULT_COLOR_LIGHT_BLUE,
  DEFAULT_COLOR_LIGHT_YELLOW,
  DEFAULT_COLOR_YELLOW,
  COLOR_KADOW_BLUE,
  COLOR_KADOW_LIGHT_GREEN,
  COLOR_BLACK,
  FOLIAGE_COLOR_DEFAULT_1,
  FOLIAGE_COLOR_DEFAULT_2,
  FOLIAGE_COLOR_DEFAULT_3,
} from './WheelColors'
import SnowFlakes from '../christmas/SnowFlakes'
import { ZINDEX_SNOWFLAKES } from '../../app/05_ScratchCard/constants'
import { buildSkinForChristmasWheel } from './skins/skin_christmas'
import { buildSkinForNatureWheel } from './skins/skin_nature'
import LotteryConfigurationResponse from '@tootsweet/model/lottery/LotteryConfigurationResponse'
import { buildSkinForValentinesDay } from './skins/skin_valentines_day'
import { buildSkinForStPatricksDay } from './skins/skin_stpatricks'
import { buildOTCPSkin } from './skins/skin_otcp'
import { buildEasterSkin } from './skins/skin_easter'
import { renderSkin } from './render_skin'
import { renderTitle } from '../../app/helpers/renderTitle'
import { ReactNode } from 'react'

interface Props extends PropsFromRedux {
  onFinishedSpinning?: (index: number, value: string) => void
  forceHideCTA?: boolean
  isPlayStep?: boolean
  hideTitle?: boolean
  finalPlaceName: string
  title?: () => ReactNode
}

interface State {
  hasPlayed: boolean
  container: HTMLDivElement | null
}

let didTrack = false

class FortuneWheelWrapper extends React.PureComponent<Props, State> {
  private WHEEL?: any

  constructor(props: Props) {
    super(props)

    this.play = this.play.bind(this)
    this.isImplemented = this.isImplemented.bind(this)
    this.isDefault = this.isDefault.bind(this)
    this.buildWheelProps = this.buildWheelProps.bind(this)
    this.getColor = this.getColor.bind(this)
    this.getColorFromColorIndex = this.getColorFromColorIndex.bind(this)
    this.buildDefaultColors = this.buildDefaultColors.bind(this)
    this.renderCTAForeground = this.renderCTAForeground.bind(this)

    this.state = {
      hasPlayed: false,
      container: null,
    }
  }

  componentDidMount() {}

  componentDidUpdate() {
    if (this.props.isPlayStep && !didTrack) {
      didTrack = true
      WheelAnalyticsManager.logEvent('wheel')
    }
  }

  render() {
    const { loading, percentCompleted, forceHideCTA } = this.props
    const { hasPlayed, container } = this.state

    const isDefault = this.isDefault()
    const isImplemented = this.isImplemented()
    const other = !isDefault && !isImplemented

    const hasRef = !!container
    let wheelDimension = hasRef && container ? container.clientHeight : 0

    let wheelLeft: string | number = Math.trunc(-(wheelDimension / 2))
    let wheelBottom: number | undefined = 0
    let wheelTop: number | undefined = undefined

    // if (isImplemented) {
    //   wheelBottom = 30
    // }

    wheelDimension = Math.trunc(wheelDimension)

    const props = this.buildWheelProps(
      this.props.skinId || '',
      wheelDimension,
      wheelLeft,
      wheelBottom,
      wheelTop
    )
    const showCTA = percentCompleted === 100

    let ctaText = !showCTA
      ? tt('loading_photo', { percentCompleted })
      : tt('spin_the_wheel')

    if (loading) {
      ctaText = tt('loading')
    }

    return (
      <>
        <div
          ref={(ref) => this.setState({ container: ref })}
          style={{ flex: 1 }}
        >
          {hasRef &&
            TSUtils.isDefinedAndNotNull(wheelDimension) &&
            !Number.isNaN(wheelDimension) && (
              <div id="fortune-wheel">
                {isDefault && (
                  <FortuneWheelV1
                    {...props}
                    ref={(ref: any) => (this.WHEEL = ref)}
                  />
                )}
                {(other || isImplemented) && (
                  <FortuneWheelV2
                    {...props}
                    ref={(ref: any) => (this.WHEEL = ref)}
                    mlDraw={this.props.mlDraw ?? undefined}
                  />
                )}
              </div>
            )}

          <div className="cta-fg">{this.renderCTAForeground(props)}</div>

          {!forceHideCTA && !hasPlayed && !loading && (
            <>
              <WheelButton
                containerClassName=""
                text={ctaText}
                isFixedBottom={true}
                onClick={this.play}
              />
            </>
          )}
        </div>

        {props.displaySnowflakes && <SnowFlakes zIndex={ZINDEX_SNOWFLAKES} />}
      </>
    )
  }

  private renderCTAForeground(props: FortuneWheelProps) {
    if (this.props.skin) {
      return renderSkin(
        'cta_fg',
        this.props.skin,
        props.colors,
        props.width,
        props.height
      )
    }
    return null
  }

  private getColor(
    key: keyof LotteryConfigurationResponse,
    defaultColor: string | undefined
  ) {
    if (
      this.props.lotteryConfig?.customizeColors &&
      this.props.lotteryConfig[key]
    ) {
      return `${this.props.lotteryConfig[key]}`
    }
    return defaultColor || ''
  }

  private buildWheelProps(
    skin: string,
    wheelDimension: number,
    wheelLeft: number | string,
    wheelBottom: number | undefined,
    wheelTop: number | undefined
  ) {
    const colors = this.buildDefaultColors()
    const styleForSkin = FortuneWheelWrapper.getStyleForSkin(skin, colors)
    const props: FortuneWheelProps = {
      config: this.props.siteConfig,
      items: this.props.wheelLabels,
      wheelItems: this.props.lotteryConfig?.wheelItems || [],

      skin,

      height: wheelDimension,
      width: wheelDimension,

      wheelLeft,
      wheelBottom,
      wheelTop,

      onFinishedSpinning: this.props.onFinishedSpinning,

      renderBackground: styleForSkin.renderBackground,
      renderForeground: styleForSkin.renderForeground,
      renderCTAForeground: styleForSkin.renderCTAForeground,

      /**
       * V1 colors
       */
      colorBack1:
        (this.props.lotteryConfig?.customizeColors &&
          this.props.lotteryConfig?.colWheel1Back) ||
        COLOR_KADOW_BLUE,
      colorFront1:
        (this.props.lotteryConfig?.customizeColors &&
          this.props.lotteryConfig?.colWheel1Front) ||
        COLOR_KADOW_LIGHT_GREEN,
      colorBack2:
        (this.props.lotteryConfig?.customizeColors &&
          this.props.lotteryConfig?.colWheel2Back) ||
        COLOR_WHITE,
      colorFront2:
        (this.props.lotteryConfig?.customizeColors &&
          this.props.lotteryConfig?.colWheel2Front) ||
        COLOR_KADOW_BLUE,

      /**
       * V2 colors
       */
      colors,

      displaySnowflakes: skin === TSConstants.WHEEL_SKIN_V2_CHRISTMAS,
      finalPlaceName: this.props.finalPlaceName,

      renderTitle: this.props.title
        ? this.props.title
        : this.props.hideTitle
        ? undefined
        : renderTitle,

      isPlayStep: this.props.isPlayStep,

      skinObj: this.props.skin,

      wheelDegInitial: this.props.lotteryConfig?.wheelDegInitial,
    }
    return props
  }

  private buildDefaultColors(): WheelColorsI {
    return {
      sector1BGColor: this.getColor('colV2Sector1BG', DEFAULT_COLOR_LIGHT_BLUE),
      sector1FGColor: this.getColor('colV2Sector1FG', COLOR_WHITE),

      sector2BGColor: this.getColor('colV2Sector2BG', COLOR_WHITE),
      sector2FGColor: this.getColor('colV2Sector2FG', COLOR_BLACK),

      sector3BGColor: this.getColor('colV2Sector3BG', DEFAULT_COLOR_LIGHT_BLUE),
      sector3FGColor: this.getColor('colV2Sector3FG', COLOR_WHITE),

      sector4BGColor: this.getColor('colV2Sector4BG', COLOR_WHITE),
      sector4FGColor: this.getColor('colV2Sector4FG', COLOR_BLACK),
      wheelText: this.getColor('colV2WheelText', undefined),

      structureFillColor: this.getColor('colV2WheelStructFill', COLOR_WHITE),
      structureFillColor2: this.getColor(
        'colV2WheelStructFill2',
        DEFAULT_COLOR_LIGHT_BLUE
      ),
      structureTopStrokeColor: this.getColor(
        'colV2WheelStructTop',
        DEFAULT_COLOR_YELLOW
      ),
      structureLinesColor: this.getColor(
        'colV2WheelStructLines',
        DEFAULT_COLOR_BLUE
      ),

      shadowColor: this.getColor('colV2ShadowColor', DEFAULT_COLOR_BLUE),

      cursorColor: this.getColor('colV2CursorColor', DEFAULT_COLOR_YELLOW),
      cursorShadowColor: this.getColor(
        'colV2CursorShadowColor',
        DEFAULT_COLOR_BLUE
      ),

      primaryLight: this.getColor('colV2PL', DEFAULT_COLOR_LIGHT_BLUE),
      primary: this.getColor('colV2P', DEFAULT_COLOR_LIGHT_BLUE),
      primaryDark: this.getColor('colV2PD', DEFAULT_COLOR_DARK_BLUE),

      secondaryLight: this.getColor('colV2SL', DEFAULT_COLOR_LIGHT_YELLOW),
      secondary: this.getColor('colV2S', DEFAULT_COLOR_YELLOW),
      secondaryDark: this.getColor('colV2SD', DEFAULT_COLOR_DARK_YELLOW),

      starColor: this.getColor('colV2Stars', DEFAULT_COLOR_DARK_YELLOW),

      balloon1ColorL: this.getColorFromColorIndex(
        this.props.lotteryConfig?.v2Balloon1ColorIdx,
        true,
        false
      ),
      balloon1Color: this.getColorFromColorIndex(
        this.props.lotteryConfig?.v2Balloon1ColorIdx,
        false,
        false
      ),
      balloon2ColorL: this.getColorFromColorIndex(
        this.props.lotteryConfig?.v2Balloon2ColorIdx,
        true,
        false
      ),
      balloon2Color: this.getColorFromColorIndex(
        this.props.lotteryConfig?.v2Balloon2ColorIdx,
        false,
        false
      ),
      gift1ColorL: this.getColorFromColorIndex(
        this.props.lotteryConfig?.v2Gift1ColorIdx,
        true,
        false
      ),
      gift1Color: this.getColorFromColorIndex(
        this.props.lotteryConfig?.v2Gift1ColorIdx,
        false,
        false
      ),
      gift1ColorD: this.getColorFromColorIndex(
        this.props.lotteryConfig?.v2Gift1ColorIdx,
        false,
        true
      ),
      gift2ColorL: this.getColorFromColorIndex(
        this.props.lotteryConfig?.v2Gift2ColorIdx,
        true,
        false
      ),
      gift2Color: this.getColorFromColorIndex(
        this.props.lotteryConfig?.v2Gift2ColorIdx,
        false,
        false
      ),
      gift2ColorD: this.getColorFromColorIndex(
        this.props.lotteryConfig?.v2Gift2ColorIdx,
        false,
        true
      ),

      v2FoliageColor1: this.getColor(
        'v2FoliageColor1',
        FOLIAGE_COLOR_DEFAULT_1
      ),
      v2FoliageColor2: this.getColor(
        'v2FoliageColor2',
        FOLIAGE_COLOR_DEFAULT_2
      ),
      v2FoliageColor3: this.getColor(
        'v2FoliageColor3',
        FOLIAGE_COLOR_DEFAULT_3
      ),

      hideBalloon: this.props.lotteryConfig?.v2HideBalloon,
      hideStars: this.props.lotteryConfig?.v2HideStars,
      hideGifts: this.props.lotteryConfig?.v2HideGifts,
    }
  }

  private static getStyleForSkin(skin: string, colors: WheelColorsI) {
    let renderBackground: any | undefined = undefined
    let renderForeground: any | undefined = undefined
    let renderCTAForeground: any | undefined = undefined
    switch (skin) {
      case TSConstants.WHEEL_SKIN_V2_CHRISTMAS:
        return buildSkinForChristmasWheel(colors)
      case TSConstants.WHEEL_SKIN_V2_NATURE:
        return buildSkinForNatureWheel(colors)
      case TSConstants.WHEEL_SKIN_V2_VALENTINES_DAY:
        return buildSkinForValentinesDay(colors)
      case TSConstants.WHEEL_SKIN_ST_PATRICKS:
        return buildSkinForStPatricksDay(colors)
      case TSConstants.WHEEL_SKIN_OTCP:
        return buildOTCPSkin(colors)
      case TSConstants.WHEEL_SKIN_EASTER:
        return buildEasterSkin()
    }
    return { renderBackground, renderForeground, renderCTAForeground }
  }

  private getColorFromColorIndex(
    idx: number | undefined,
    isLight: boolean,
    isDark: boolean
  ) {
    if (isDark) {
      return idx
        ? this.getColor('colV2SD', DEFAULT_COLOR_DARK_YELLOW)
        : this.getColor('colV2PD', DEFAULT_COLOR_DARK_BLUE)
    }
    if (isLight) {
      return idx
        ? this.getColor('colV2SL', DEFAULT_COLOR_LIGHT_YELLOW)
        : this.getColor('colV2PL', DEFAULT_COLOR_LIGHT_BLUE)
    }
    return idx
      ? this.getColor('colV2S', DEFAULT_COLOR_YELLOW)
      : this.getColor('colV2P', DEFAULT_COLOR_BLUE)
  }

  private async play() {
    if (this.state.hasPlayed) {
      return
    }

    WheelAnalyticsManager.logEvent('wheel_play')

    const { user, isOptOut, siteConfig } = this.props

    if (user) {
      await this.props.async_play(
        siteConfig,
        user.firstName,
        user.paymentEmail,
        isOptOut,
        user.phone,
        true
      )
    }

    if (this.WHEEL && this.props.prize) {
      this.setState({ hasPlayed: true })
      this.WHEEL.play(this.props.prize.index)
    }
  }

  private isImplemented() {
    return (
      this.props.skinId === TSConstants.WHEEL_SKIN_V2_NEUTRAL ||
      this.props.skinId === TSConstants.WHEEL_SKIN_V2_CHRISTMAS ||
      this.props.skinId === TSConstants.WHEEL_SKIN_V2_NATURE ||
      this.props.skinId === TSConstants.WHEEL_SKIN_V2_VALENTINES_DAY ||
      this.props.skinId === TSConstants.WHEEL_SKIN_ST_PATRICKS ||
      this.props.skinId === TSConstants.WHEEL_SKIN_OTCP ||
      this.props.skinId === TSConstants.WHEEL_SKIN_EASTER
    )
  }

  private isDefault() {
    return this.props.skinId === TSConstants.WHEEL_SKIN_DEFAULT
  }
}

export const mapStateToProps = (state: RootState) => {
  return {
    user: state.app.user,
    wheelLabels: state.lottery.wheelLabels,
    siteConfig: state.config,
    lotteryConfig: state.lottery.lotteryConfig,
    percentCompleted: state.lottery.percentCompleted,
    step: state.lottery.step,
    prize: state.lottery.prize,
    loading: state.lottery.loading,
    isOptOut: state.lottery.isOptOut,
    skin: state.lottery.skin,
    skinId: state.lottery.skinId,
    mlDraw: state.lottery.draw,
  }
}

export const mapDispatchToProps = {
  async_play,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(FortuneWheelWrapper)
